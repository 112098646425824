<template>
  <div class="bank-card">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>银行卡</span>
      <span></span>
    </header>
    <main class="page-body"></main>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
</style>
